import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { AgencyCdp } from '../interfaces/agency.interface';
import { StorageService } from './storage.service';
import { ChangeNameRequest } from '../models/validation-request-type.model';
import { ChangeNameResponse } from '../models/validation-response-type.model';


@Injectable()
export class CorrectNameService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  constructor(
    private http: HttpClient) { 

    }


  validate(changeNameRequest: ChangeNameRequest): Observable<ChangeNameResponse> {   
    return this.http.post<ChangeNameResponse>(`${this.urlBase}/correctname/validate`, changeNameRequest, { headers: this.headers });
  }
  
  transaction(changeNameRequest: ChangeNameRequest): Observable<ChangeNameResponse> {
    return this.http.post<ChangeNameResponse>(`${this.urlBase}/correctname/transaction`, changeNameRequest, { headers: this.headers });
  }


}
