import { Component } from '@angular/core';
import { FormCorrectnameComponent } from '../form-correctname/form-correctname.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageErrorService } from '../../../services/message-error.service';

@Component({
  selector: 'app-modal-validate-correct-name',
  templateUrl: './modal-validate-correct-name.component.html',
  styleUrls: ['./modal-validate-correct-name.component.css']
})
export class ModalValidateCorrectNameComponent {

  newFullName: string;
  oldFullName: string;
  

  ngOnInit(): void { 
    

  }


  constructor(
    private dialogRef: MatDialogRef<ModalValidateCorrectNameComponent>,
    private messageErrorService: MessageErrorService

  ){ }

  confirmCorrection(){
      this.dialogRef.close(true);
  }

  
}
